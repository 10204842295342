/**
 * Licent
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Watchalong Controller.
 */
export default class extends Controller {
  static targets = ['sessionCode'];

  /**
   * Handle form submission
   * @param {Event} event
   */
  submit(event) {
    event.preventDefault();

    const sessionCode = this.sessionCodeTarget.value;
    const url = `https://schermdelen.nl/${sessionCode}`;

    window.open(url, '_blank');
  }
}
